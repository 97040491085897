import './App.css';
import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import {Skeleton} from './Skeleton.js';
import {HomePage} from './Home.js';
import {Projects} from './Projects.js';
import {Skills} from './Skills.js';
import {Testimonials} from './Testimonials.js';

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Skeleton/>}>
            <Route index element={<HomePage />} />
            <Route path="projects" element={<Projects />} />
            <Route path="skills" element={<Skills />} />
            <Route path="testimonials" element={<Testimonials />} />

            <Route path="*" element={<NoMatch />} />
          </Route>
      </Routes>
    </React.Fragment>
  );
}

function NoMatch() {
  return (
    <div>
      <h3>Nothing to see here!</h3>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
export default App;

import React from 'react';

import softwareDeveloper from './assets/softwareDeveloper.jpg';
import problemSolver from './assets/problem-solver.png';
import processImprover from './assets/process-improver.jpg';
import innovator from './assets/Innovator.jpg';
import rightArrow from './assets/RightArrowtransparent.png';
import leftArrow from './assets/LeftArrotransparent.png';

let srcs= [
  [softwareDeveloper, ["Software", "Engineer"]],
  [problemSolver, ["Problem", "Solver"]],
  [innovator, ["Innovator"]],
  [processImprover, ["Process", "Improver"]]
]

export const HomePage = () => {
  const [tabNumber, setTabNumber] = React.useState(1);
  return (
    <div id="home">
      <div id="intro">Hi, I'm <span className="bigger">Ben Tanaka</span>. I'm a ...</div>

      <div id="container">
        {tabNumber > 1 ? <Arrow right={false} setTabNumber={setTabNumber}/> : null}
        <img id = "traitIMG" src={srcs[tabNumber-1][0]} alt={srcs[tabNumber-1][1]}></img>
        <div className="traits" key={tabNumber}>
          {srcs[tabNumber-1][1].map((item, index)=> {
            return <div key={index}>{item}</div>
          })}
          </div>
        {tabNumber < 4 ? <Arrow right={true} setTabNumber={setTabNumber}/> : null}
        <div id="circles">
          {[1,2,3,4].map((item)=> {
            if (item === tabNumber) {
              return <div key={item} className="solidCircle"></div>
            } else {
              return <div key={item} className="whiteCircle" onClick={()=>(setTabNumber(item))}></div>
            }
          })}
        </div>
      </div>
      <div id="intro2">...I'm proficient in a wide range of backend and frontend technologies.
          <br></br>
          <br></br>
          <div id="mystory">My Story</div>
          <br></br>
          <span id ="intro3">In mid 2022, after getting a good taste of Python coding, I left my semi-related job to begin a vigorous transition to the software engineering field. I attended Hack Reactor that fall and learned new libraries/frameworks, computer science fundamentals, and industry standard practices such as managing workflows through git, paired programming, and navigating a developer environment in VSCode/CLI. Since graduating, I've been working on projects and doing a deeper dive self-study of data structures and algorithms.</span>
          {/* <a href="https://investor.pattersoncompanies.com/investor-relations/press-releases/press-release-details/2023/Patterson-Companies-Reports-Fiscal-2024-First-Quarter-Operating-Results/default.aspx">patersonLink</a> */}
          {/* <a href="https://ir.constructionpartners.net/news/press-releases/detail/108/construction-partners-inc-announces-fiscal-2023-third">construc partners</a> */}
      </div>
      <div className="TimeLineContainer">
          <div id="centerline"></div>
          <div id="years">
            <div className="year">2021</div>
            <div className="year">2022</div>
            <div className="year">2023</div>
          </div>

          {/* <div id="HackReactorLines"><div id="HRrDescBox"><p id="HRDesc">Hack Reactor</p></div></div> */}
          <div className="descBox" id="HackReactorLines"><div id="HRrDescBox"><ul id="HRDesc"><span className="bigger">Hack Reactor</span>
            <br></br>
            <em>Student</em>
            <li>Full stack coding bootcamp</li>
          </ul></div></div>

          {/* <div id="BaxterLines"><div id="BaxterDescBox"><p id="BaxterDesc">Baxter International</p></div></div> */}
          <div className="descBox" id="BaxterLines"><div id="fadedOrange"></div><div id="BaxterDescBox"><ul id="BaxterDesc"><span className="bigger">Baxter International</span>
          <br></br>
            <em>PSQA</em>
            <li>Data analysis</li>
            <li>Process improvement</li>
            <li>Project management</li>
            <li>Python development</li>
          </ul></div></div>

          <div className="descBox" id="projectLines"><div id="projectLinesDescBox"><ul id="projectLinesDesc"><span className="bigger">Projects</span>
            <br></br>
            <li>Apps during Hack Reactor</li>
            <li>Further development</li>
            <li>Data structures/algorithms study</li>
          </ul></div></div>

        </div>
    </div>
  )
}

const Arrow = ({right, setTabNumber}) => {
  return (
    <img className={right? "rightArrow" : "leftArrow"} src={right? rightArrow : leftArrow} width="50px" height="63px"
    onClick={()=>(setTabNumber(a=>a+(right? 1 : -1)))} alt={right? "Next":"Previous"}></img>
  )
}
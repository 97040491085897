import React from 'react';

// import tile2image1Round from './assets/tile2image1Round.mp4';
import ICON_aws from './assets/ICON-aws.svg';
import ICON_express from './assets/ICON-express.svg';
import ICON_javascript from './assets/ICON-javascript.svg';
import ICON_node from './assets/ICON-node.svg';
import ICON_postgresql from './assets/ICON-postgresql.svg';
import ICON_react from './assets/ICON-react.svg';
import ICON_html from './assets/ICON-html.svg';
import ICON_jest from './assets/ICON-jest.svg';
import ICON_postman from './assets/ICON-postman.svg';
import ICON_css from './assets/ICON-css.svg';
import ICON_git from './assets/ICON-git.svg';
import ICON_webpack from './assets/ICON-webpack.svg';
import ICON_babel from './assets/ICON-babel.svg';
import ICON_axios from './assets/ICON-axios.svg';

import tile2image_ICON from './assets/Tile2ImageICON.png';
// import tile2Image_server from './assets/tile2Image_server.mp4';
import NeuRaveICON from './assets/NeuRaveICON.png';
import QandAServiceICON from './assets/QandAServiceICON.png';
import L77Icon from './assets/L77Icon.png';
// import wikiFlowChart from './assets/WikiFlowchart.png';
// import exit_sign from './assets/exit_sign.png';

import QandA_API_service_preoptimize from './assets/QandA_API_service_preoptimize.png';
import QandA_API_Service_passTests from './assets/QandA_API_Service_passTests.png';
import QandA_API_service_loadIO_1 from './assets/QandA_API_service_loadIO_1.png';
import QandA_API_service_loadIO_2 from './assets/QandA_API_service_loadIO_2.png';

import L77_click_tracking from './assets/L77_click_tracking.mp4'
import githubICON from './assets/GitHub_logo_2013.svg';

export const Projects = () => (
  <div id="projectOUTERDIV">
    <div id="newProjectContainer">
      <div className="iconlink">
        <h2>1</h2>
        <a href="#project1" >
          <img className="projectIcon" src={L77Icon} alt="L77"></img>
        </a>
      </div>

      <div className="iconlink">
        <h2>2</h2>
        <a href="#project2" to="/projects/QandAService">
          <img className="projectIcon" src={QandAServiceICON} alt="Q and A service"></img>
        </a>
      </div>

      <div className="iconlink">
        <h2>3</h2>
        <a href="#project3" to="/projects/Tile2Image">
          <img className="projectIcon" src={tile2image_ICON} alt="Tile Game"></img>
        </a>
      </div>

      <div className="iconlink">
        <h2>4</h2>
        <a href="#project4" to="/projects/NeuRave">
          <img className="projectIcon" src={NeuRaveICON} alt="NeuRave"></img>
        </a>
      </div>
    </div>

    <SingleProject titlez='1. L77' descript="Frontend for an online clothing store, supported by an existing backend via a Hack Reactor API which provided fictional store data. Each team member was responsible for 1 of 4 sections: Product Overview, Related Products, Q&A, and Ratings/Reviews. I was assigned Ratings/Reviews and built a UI similar to Amazon's." typez='4 person team, frontend' iconz={[ICON_javascript, ICON_react, ICON_css, ICON_html, ICON_axios, ICON_node, ICON_postgresql, ICON_express, ICON_aws, ICON_webpack, ICON_babel,ICON_git, ICON_jest]}
    mediaz={L77_Media} buildstepz={L77BuildSteps} gitpathz='https://github.com/BTanaka11/L77' deployz={[true,"https://amplify-deploy.d469pfy0cj7c4.amplifyapp.com"]}></SingleProject>

    <SingleProject titlez='2. Q&A API Service' descript="This was a back-end follow-up project to L77, in which a front-end clothing store website was built using an external API for its data needs. Of the front-end web app's 4 pages (Product Information, Related Products, Q&A, and Ratings/Reviews), I took Q&A and built an API service for it. Through continuous testing and optimization, I scaled it up to handle thousands of requests/second and deployed it as a microservice, allowing it to be interchangable on the frontend." typez='Solo backend' iconz={[ICON_javascript, ICON_postgresql, ICON_aws, ICON_node, ICON_express,ICON_postman,ICON_git,ICON_jest]}
    mediaz={QandA_API_Media} buildstepz={QandA_API_Build_Steps} gitpathz='https://github.com/BTanaka11/Q-A_API_Service' deployz={[false, "N/A"]}></SingleProject>

    <SingleProject titlez='3. Tile 2 Image' descript="Puzzle game where players rotate tiles to restore a split image. The image is randomly selected from a Shutterstock API, though users can pick from around 20 image categories. There's a public leaderboard for scores, which are based on amount of tiles the player picks, how long it took to solve the puzzle, and amount of hints used. Hints glow the unsolved tiles for around 1 second but apply a 50% score penalty." typez='Solo full stack' iconz={[ICON_javascript, ICON_react, ICON_css, ICON_html, ICON_axios, ICON_node, ICON_postgresql, ICON_express, ICON_aws, ICON_webpack, ICON_babel,ICON_git, ICON_jest]}
mediaz={Tile2ImageMedia} buildstepz={Tile2ImageBuildSteps} gitpathz='https://github.com/BTanaka11/Tile-2-Image' deployz={[true,"http://ec2-52-14-197-230.us-east-2.compute.amazonaws.com:3000/"]}></SingleProject>

    <SingleProject titlez='4. NeuRave' descript="Music themed social media and event planning app. The 4 main features are a Login/Home, Discover, Group, and Profile page. After our initial group planning phase, we divided into pairs, each being responsible for 1 feature. I was assigned to the Discover page, which is the primary means by which external data enters and is saved within the app. After authenticated login with their Spotify account, users can search the Discover page for real music events from a Seatgeek API and create groups for them, storing select data into our own database. For this Discover page, I did the backend work and my partner did the frontend work (though I helped out a lot). Also, I was voted to be the project Architect and designed the database, deploying as its own EC2 instance for use by whole team." typez='8 person team, full stack' iconz={[ICON_javascript, ICON_node, ICON_postgresql, ICON_express, ICON_aws, ICON_webpack,ICON_babel, ICON_axios, ICON_react, ICON_css, ICON_git, ICON_jest]}
    mediaz={NeuRaveMedia} buildstepz={NeuRaveBuildSteps} gitpathz='https://github.com/BTanaka11/neuRave' deployz={[false,"N/A"]}></SingleProject>

  </div>
)

const L77BuildSteps = ['Designed a React parent/child component skeleton, with product ID held as state at the highest level.', 'For highest level "RatingsReviews" component, setup fetching ratings/reviews data from the external API via Axios.js GET requests within a useEffect hook.' ,'Fleshed out the Breakdown and Review Lists child components, passing state setters as props to ensure user querying and filtering triggers the parent component to refetch API data and rerender.', 'Used StyledComponents.js to maintain a performant and reasonably styled UI, all within React code.', 'Completed the new review form, with support for photo uploads by using a 2nd external API called Cloudinary to store the photos on the cloud and generate photo URLs, which were then saved by the 1st external API.', 'Achieved 90% test coverage with Jest.js.', 'Continued packing in more features including ability to mark reviews as helpful, report reviews, and field verification in the new review form.']
const L77_Media = ()=>(<div className="mediaDiv">
  <Photoz srcz="https://user-images.githubusercontent.com/37204126/204711146-2df11b8f-b82b-4717-9916-57844d55dea8.gif" caption="Ratings/reviews section." />
  <br></br><br></br>
  {/* <Video srcz="https://user-images.githubusercontent.com/37204126/223297320-2e7ff5cf-178c-4df8-b53d-9267601306a8.mp4" caption="Demonstrating my successful implementation of click tracking to record userclicks for each of the 4 sections/Widgets." /> */}
  <Video srcz={L77_click_tracking} caption="Click tracking to record userclicks for each of the 4 sections." />

</div>)

const QandA_API_Build_Steps= ["Designed a PostgreSQL database schema.", "Performed an ETL to load millions of records from 3 csv files into the database.", "Created 8 RESTful service routes to (1) get questions, (2) get answers, (3) post a question, (4) post an answer, (5) mark an answer helpful, (6) mark a question helpful, (7) report a question, (8) report an answer.", "Tested with Jest.js to confirm all routes were returning or manipulating the data in the expected format.", "Obtained initial, baseline response time metrics with the software tool Postman.", "Applied an optimization of indexing 1 column in each of the 3 tables.", "Applied another optimization of making my server's GET routes to do more of the computation via SQL statements rather than JavaScript array maniplation.", "Tested with JMeter to confirm improvements vs the baseline.", "Deployed database and server as AWS EC2 microinstances.", "Tested the deployed service with Loader.io and shifted focus to scaling-up by monitoring how many requests per second it could handle under load.", "Applied an optimization technique of caching GET results using a custom solution I built from scratch.", "Retested via Loader.io to confirm performance gains."]
const QandA_API_Media = ()=>(<div className="mediaDiv">
  <Photoz srcz={QandA_API_service_preoptimize} caption="Postman initial GET answers response time." />
  <br></br><br></br>
  <Photoz srcz={QandA_API_Service_passTests} caption="Confirming data format is right, letting me focus remaining effort on server performance optimiziation." />
  <br></br><br></br>
  <Photoz srcz={QandA_API_service_loadIO_1} caption="Loader.io throughput after deployment and prior to caching." />
  <br></br><br></br>
  <Video srcz="https://user-images.githubusercontent.com/37204126/223299851-b8932ae1-1843-49bd-9748-3f35f15e49c3.mp4" caption="My cache data structure passing a test I wrote." />
  <br></br><br></br>
  <Photoz srcz={QandA_API_service_loadIO_2} caption="Loader.io throughput after caching." />
</div>)

const NeuRaveBuildSteps = ["Designed a PostgreSQL database schema by studying Seatgeek API's data and understanding the needs of teammates' pages.", "Deployed the database as its own EC2 microinstance and configured it for remote use by the whole team.", "Created a RESTful server route to receive music event search parameters from the frontend Discover page.", "Further studied the Seatgeek API results for a wide range of search parameters and adjusted the database accordingly. EX: changed zipcode data type from number to string due to the possiblility of Canadian music events being returned, which have alphanumeric zip codes.", "Added a step to this RESTful server route to compare the Seatgeek API results to my database's Events/Users tables, appending an array of users already subscribed to each event (to support step#7 below)", "Completed this RESTful server route by returning search results to the client and assisted teammate in rendering results as React card components.", "Utilized the extra information appended in step #5 above to differentiate what the button in each React card component did based on whether the event already existed in my database for any user and whether it already existed for the logged in user.", "Created a final RESTful server route so that when a user searches for events and finds one they're not already in, they can click the button on the React card component to save the new event to the database and join it or join an existing event.", "Assisted the pair working on the Group page, which had a chatbox, by enhancing it to use realtime chat via socket.io."]
const NeuRaveMedia = ()=>(<div className="mediaDiv">
  <Video srcz="https://user-images.githubusercontent.com/37204126/223301520-f6724939-62ce-4276-a7bf-8cd617232f27.mp4" caption="Showing my schema, a demonstration of remote connection to the database, and what some of the data looked like." />
  <br></br><br></br>
  <Video srcz="https://user-images.githubusercontent.com/37204126/223301349-ab214f01-0ab3-4035-a603-3533283207f0.mp4" caption="The Discover page in action."/>
</div>)

const Tile2ImageBuildSteps = ['Created an Express.js server to retrieve a list of image categories and random image within a category, from an external Shutterstock API.', 'Made the frontend homepage with React and game settings form for users to input their name, image category, and tile count.', 'Connected frontend game settings form to the backend via Axios.js.', 'Created a PostgreSQL leaderboard table to store scores.', 'Connected database to server and server to frontend.',  'Made the frontend homepage read/display top scores and the game component post a score upon game completion.', 'Began developing the frontend game component by implementing image splitting in a way that optimizes total tile surface area without changing image resolution/aspect-ratio but with cropping.', 'Created a React architecture consisting of state for a matrix of tiles, each starting at a random 90 degree rotation, and React child component for the tiles.', 'Implemented a timer and the essentials for a functional game, which were onClick events to rotate tiles and a mechanism to detect the end condition when all tiles were rotated to correct position.', 'Deployed to AWS EC2.', 'Added a hint feature which highlighted unsolved tiles and detracted from score.', 'Improved the visuals by adding tile rotation animations and a 3 second round opening sequence showing image cropping, splitting, and rotating process.']
const Tile2ImageMedia = ()=>(<div className="mediaDiv">
  <Video srcz='https://user-images.githubusercontent.com/37204126/223302863-b80f8043-ae25-4182-ae43-04fda4fd59c5.mp4' caption="Server routes for retrieving image data from the Shutterstock API, and what the data looked like for them and my leaderboard database." />
  <br></br><br></br>
  <Video srcz='https://user-images.githubusercontent.com/37204126/223303865-0ad0901e-240b-4f77-ba89-c8bedb489860.mp4' caption="1 full round of gameplay." />
</div>)

const SingleProject = ({titlez, descript, typez, iconz, mediaz, buildstepz, gitpathz, deployz}) => (
  <div id={'project' + titlez[0]} className='ProjectzContainer'>
    <strong className="ProjectTitle">{titlez}</strong>
    <p><span className="bigger">Description: </span>{descript}
    <br></br>
    <br></br>
    <span className="bigger">Project Type: </span>{typez}
    <br></br>
    </p>
    <div className="projectIconsHolder">
      <label style={{'paddingRight': '5px'}}><span className="bigger">Tech:  </span></label>
      {iconz.map((icon,index)=>{
        return <img className="iconBoxed" src={icon} alt="Tile Game" width="40vw" height="40vw" key={index}></img>
      })}
    </div>
    <br></br>

    <div className="gitz"><span className="bigger">Code: </span><GitIcon pathWay={gitpathz}/></div>
    <p><span className="bigger">Deployment: </span>
    {deployz[0] ? <a style={{'textDecoration': 'underline'}} href={deployz[1]} target="_blank" rel="noopener noreferrer">Link</a> : <span>{deployz[1]}</span>}
    </p>

    <span className="bigger">Video and Screenshots: </span>
    {mediaz()}

    <span className="bigger">Build Steps: </span>
    <ol>
      {buildstepz.map((step,index)=> (
        <li className="buildStep" key={index}>{step}</li>
      ))}
    </ol>

  </div>
)

const Photoz = ({srcz, caption}) => (
  <figure>
    <img className="photo_or_vid" src={srcz} alt="x"></img>
    <figcaption>{caption}</figcaption>
  </figure>
)
const Video = ({srcz, caption}) => (
  <figure>
    <video className="photo_or_vid" autoPlay controls loop>
      <source src={srcz} type="video/mp4" />
    </video>
    <figcaption>{caption}</figcaption>
  </figure>
)

// let CacherDetails = ({setCacheDetails}) => (
//   <div style={{display: "inline"}}>
//     <img className="exitSign" src={exit_sign} onClick={()=>(setCacheDetails(a=>!a))} alt="EXIT"></img>
//     <div>
//       <p>I decided to implement my own custom server-side caching. Using the diagram to the right, from wikipedia, as inspiration, I created 2 caches…</p>
//       <ol>
//         <li>storing getQuestions results with product_id being the key</li>
//         <li>storing getAnswer results with question_id being the key</li>
//       </ol>
//       <p>…each with a cache limit of 500 and following the same “Read” flow as the diagram for the respective GET routes. I implemented write in a different way.</p>
//       <hr></hr>
//       <p><strong>POST ANSWERS</strong> - if the question_id exists in cache#2, it'll delete it from cache, do a SQL query to get the question_id's product_id, and delete the product_id from cache#1 if present. these async steps are triggered and the next block of DB Insert async steps are triggered without waiting.</p>
//         <p><strong>JUSTIFICATION</strong> - if new answer is posted, it would impact both the expected GET answers results and GET Questions results (since it contains nested answers). following the wiki flowchart and writing data to cache#2 isn't straightforward as the flowchart because the cached results are like an array of answers for the question, so it'd either need to get the cached results and append the posted answer to the array or do a SQL query to get the new full array of answers and write that to the cache. Because these are ugly and costly steps and app's users will likely do more GETs than POSTs, I decided to simply delete it from the cache, which will make the next GET ANSWERS requery the db for full answer list and cache that. Same justification for deleting from cache#1. </p>
//         <p><strong>POST QUESTIONS</strong> - if product_id exists in cache#1, delete it from cache. these are synchronous steps.</p>
//         <p><strong>JUSTIFICATION</strong> - no need to worry about interacting with cache#2 or integrity of its data because there will always be 0 answers at the start for a new question.</p>
//     </div>
//     <img src={wikiFlowChart} alt="wiki Cache Flowchart"></img>
//   </div>
// )

const GitIcon = ({pathWay}) => (
  <a href={pathWay} target="_blank" rel="noopener noreferrer" style={{height:"inherit", display:'contents'}}>
    <img className="projectGIT" src={githubICON} alt="n"></img>
  </a>
)
import React from 'react';
import {NavBar} from './NavBar.js';
import { Outlet } from "react-router-dom";
import {Bottom} from './Bottom.js';

export const Skeleton = () => {
  const TOP_BAR_REF = React.useRef('')
  const MIDDLE_BAR_REF = React.useRef('')
  const BOTTOM_BAR_REF = React.useRef('')

  React.useEffect(() => {

    let handleScrollMaker = () => {
      let position = 0;
      let originalBarHeight = 90;
      let topBarPixelHeight = 90;
      let bottomBarPixelHeight = 0;
      return () => {
        let pageOff = window.pageYOffset;
        let pixelShift = pageOff - position;
        position = pageOff;
        if (pixelShift > 0 && bottomBarPixelHeight < originalBarHeight) {
          topBarPixelHeight -= pixelShift;
          if (topBarPixelHeight < 0) {topBarPixelHeight = 0;}
            TOP_BAR_REF.current.style.height = topBarPixelHeight  + 'px'
            MIDDLE_BAR_REF.current.style.marginTop = topBarPixelHeight + 'px'

            bottomBarPixelHeight += pixelShift;
            if (bottomBarPixelHeight > originalBarHeight) {bottomBarPixelHeight = originalBarHeight}
            BOTTOM_BAR_REF.current.style.height = bottomBarPixelHeight  + 'px'
            MIDDLE_BAR_REF.current.style.marginBottom = bottomBarPixelHeight + 'px'

          } else if (pixelShift < 0 && topBarPixelHeight < originalBarHeight) {
            topBarPixelHeight -= pixelShift;
            if (topBarPixelHeight > originalBarHeight) {topBarPixelHeight = originalBarHeight};
            TOP_BAR_REF.current.style.height = topBarPixelHeight  + 'px'
            MIDDLE_BAR_REF.current.style.marginTop = topBarPixelHeight + 'px'

            bottomBarPixelHeight += pixelShift;
            if (bottomBarPixelHeight < 0) {bottomBarPixelHeight = 0};
            BOTTOM_BAR_REF.current.style.height = bottomBarPixelHeight  + 'px'
            MIDDLE_BAR_REF.current.style.marginBottom = bottomBarPixelHeight + 'px'
          }
      }
    }
    const handleScroll = handleScrollMaker()

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <React.Fragment>
      <div ref={TOP_BAR_REF} id="TOP_BAR">
        <NavBar/>
      </div>

      <div ref={MIDDLE_BAR_REF} id="MIDDLE_BAR">
        <Outlet/>
        <div id="copyright">Created and Designed by Ben Tanaka</div>
      </div>

      <div ref={BOTTOM_BAR_REF} id="BOTTOM_BAR">
        <Bottom />
      </div>
    </React.Fragment>
  )
}

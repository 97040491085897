import { Link } from "react-router-dom";
import React from 'react';
import myResume from './assets/Ben Tanaka Resume.pdf'

export const NavBar = () => (

  <React.Fragment>
    <Link className="navButtons" to="/" style={{textDecoration: 'none'}}><div className="wrapper">Home</div></Link>
    <Link className="navButtons" to="/projects" style={{textDecoration: 'none'}}><div className="wrapper">Projects</div></Link>
    <Link className="navButtons" to="/skills" style={{textDecoration: 'none'}}><div className="wrapper">Skills</div></Link>
    <Link className="navButtons" to="/testimonials" style={{textDecoration: 'none'}}><div className="wrapper">Testimonials</div></Link>
    {/* <span className="navButtons" ><a href="https://www.linkedin.com/in/bentanaka/overlay/1635517861087/single-media-viewer/?profileId=ACoAAD3Ff58B05JmUXeJevpuj0UWXHKGS49TZQY" target="_blank" rel="noreferrer">Resume</a></span> */}
    <span className="navButtons" ><a href={myResume} target="_blank" rel="noreferrer"><div className="wrapper">Resume</div></a></span>
  </React.Fragment>
)

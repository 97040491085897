import React from 'react';
import ICON_aws from './assets/ICON-aws.svg';
import ICON_express from './assets/ICON-express.svg';
import ICON_javascript from './assets/ICON-javascript.svg';
import ICON_node from './assets/ICON-node.svg';
import ICON_postgresql from './assets/ICON-postgresql.svg';
import ICON_react from './assets/ICON-react.svg';
import ICON_html from './assets/ICON-html.svg';
import ICON_jest from './assets/ICON-jest.svg';
import ICON_postman from './assets/ICON-postman.svg';
import ICON_css from './assets/ICON-css.svg';
import ICON_git from './assets/ICON-git.svg';
import ICON_webpack from './assets/ICON-webpack.svg';
import ICON_babel from './assets/ICON-babel.svg';
import ICON_axios from './assets/ICON-axios.svg';
import ICON_bash from './assets/ICON-bash.svg';
import ICON_mongodb from './assets/ICON-mongodb.svg';
import ICON_mysql from './assets/ICON-mysql.svg';
// import ICON_other from './assets/ICON-other.svg';
import ICON_pandas from './assets/ICON-pandas.svg';
import ICON_python from './assets/ICON-python.svg';
import ICON_scikitlearn from './assets/ICON-scikitlearn.png';
import ICON_vba from './assets/ICON-vba.svg';
import sort_icon from './assets/sort_icon.png';

const memoizeCousinOne = () => {
  let prioritize = {
    'Language': 1,
    'Backend': 2,
    'Frontend': 3,
    'Deploy/ Test': 4,
    'Developer Tool': 5
  }
  let sortedBool = true;
  return (arrayz) => {
    sortedBool = !sortedBool;
    return [...arrayz].sort((a, b) => (
      sortedBool ? prioritize[a.type] - prioritize[b.type] : prioritize[b.type] - prioritize[a.type]
    ))
  }
}

let sortByCategory = memoizeCousinOne();

const memoizeCousinTwo = () => {
  let prioritize = {
    'Advanced': 1,
    'Proficient': 2,
    'Intermediate': 3,
  }
  let sortedBool = true;
  return (arrayz) => {
    sortedBool = !sortedBool;
    return [...arrayz].sort((a, b) => (
      sortedBool ? prioritize[a.skill_level] - prioritize[b.skill_level] : prioritize[b.skill_level] - prioritize[a.skill_level]
    ))
  }
}

let sortBySkill = memoizeCousinTwo();

const memoizeCousinThree = () => {
  let sortedBool = true;
  return (arrayz) => {
    sortedBool = !sortedBool;
    return [...arrayz].sort((a, b) => (
      sortedBool ? a.year_last_used-b.year_last_used : b.year_last_used-a.year_last_used
    ))
  }
}

let sortByYearUsed = memoizeCousinThree();

let skillsArray1 = [
  {
    name: 'JavaScript',
    type: 'Language',
    skill_level: 'Advanced',
    icon: ICON_javascript,
    year_last_used: 2023
  },
  {
    name: 'Python',
    type: 'Language',
    skill_level: 'Advanced',
    icon: ICON_python,
    year_last_used: 2022
  },
{
  name: 'VBA',
  type: 'Language',
  skill_level: 'Advanced',
  icon: ICON_vba,
  year_last_used: 2021
},
{
  name: 'Node.js',
  type: 'Backend',
  skill_level: 'Proficient',
  icon: ICON_node,
  year_last_used: 2023
},
{
  name: 'PostgreSQL',
  type: 'Backend',
  skill_level: 'Proficient',
  icon: ICON_postgresql,
  year_last_used: 2023
},
{
  name: 'Mongodb',
  type: 'Backend',
  skill_level: 'Intermediate',
  icon: ICON_mongodb,
  year_last_used: 2022
},
{
  name: 'MySQL',
  type: 'Backend',
  skill_level: 'Intermediate',
  icon: ICON_mysql,
  year_last_used: 2022
},
{
  name: 'Express.js',
  type: 'Backend',
  skill_level: 'Proficient',
  icon: ICON_express,
  year_last_used: 2023
},
{
  name: 'Pandas',
  type: 'Backend',
  skill_level: 'Advanced',
  icon: ICON_pandas,
  year_last_used: 2022
},
{
  name: 'Scikit-learn',
  type: 'Backend',
  skill_level: 'Intermediate',
  icon: ICON_scikitlearn,
  year_last_used: 2021
},
  {
    name: 'React.js',
    type: 'Frontend',
    skill_level: 'Advanced',
    icon: ICON_react,
    year_last_used: 2023
  },
{
  name: 'CSS',
  type: 'Frontend',
  skill_level: 'Intermediate',
  icon: ICON_css,
  year_last_used: 2023
},
{
  name: 'HTML',
  type: 'Frontend',
  skill_level: 'Proficient',
  icon: ICON_html,
  year_last_used: 2023
},
{
  name: 'Axios.js',
  type: 'Frontend',
  skill_level: 'Advanced',
  icon: ICON_axios,
  year_last_used: 2022
},
{
  name: 'AWS',
  type: 'Deploy/ Test',
  skill_level: 'Intermediate',
  icon: ICON_aws,
  year_last_used: 2023
},
{
  name: 'Jest.js',
  type: 'Deploy/ Test',
  skill_level: 'Intermediate',
  icon: ICON_jest,
  year_last_used: 2022
},
{
  name: 'Postman',
  type: 'Deploy/ Test',
  skill_level: 'Proficient',
  icon: ICON_postman,
  year_last_used: 2022
},
{
  name: 'Git',
  type: 'Developer Tool',
  skill_level: 'Intermediate',
  icon: ICON_git,
  year_last_used: 2023
},
{
  name: 'Webpack',
  type: 'Developer Tool',
  skill_level: 'Intermediate',
  icon: ICON_webpack,
  year_last_used: 2022
},
{
  name: 'Babel.js',
  type: 'Developer Tool',
  skill_level: 'Intermediate',
  icon: ICON_babel,
  year_last_used: 2022
},
{
  name: 'Bash',
  type: 'Developer Tool',
  skill_level: 'Intermediate',
  icon: ICON_bash,
  year_last_used: 2023
}
];

export const Skills = () => {
  const [skillsList, setSkillsList] = React.useState(skillsArray1);

  return (
    <div id="tableDiv">
      <table>
        <thead>
          <tr>
            <th>Technology</th>
            <th><div className="attributeDiv">Category <img className="sorter" src={sort_icon} onClick={()=>(setSkillsList(sortByCategory(skillsList)))}alt="sort"></img></div></th>
            <th><div className="attributeDiv">Skill Level <img className="sorter" src={sort_icon} onClick={()=>(setSkillsList(sortBySkill(skillsList)))}alt="sort"></img></div></th>
            <th><div className="attributeDiv">Year Last Used <img className="sorter" src={sort_icon} onClick={()=>(setSkillsList(sortByYearUsed(skillsList)))}alt="sort"></img></div></th>
          </tr>
        </thead>
        <tbody>
        {skillsList.map((skill, index)=> (
          <tr key={skill.name}>
            <td>
              <div className="imgwithinCell">
                <img src={skill.icon} width="25%" alt={index}></img>
                <span>{skill.name}</span>
              </div>
            </td>
            <td>{skill.type}</td>
            <td>{skill.skill_level}</td>
            <td>{skill.year_last_used}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}
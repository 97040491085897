import React from 'react';
import linkedInIcon from './assets/LinkedIn_icon.svg';
import githubICON from './assets/GitHub_logo_2013.svg';

export const Bottom = () => (

  <React.Fragment >
    <a href="https://github.com/BTanaka11" target="_blank" rel="noopener noreferrer" style={{height:"inherit", display:'contents'}}>
      <img src={githubICON} alt="n" style={{height:"50%"}}></img>
    </a>
    <a href="https://www.linkedin.com/in/bentanaka/" target="_blank" rel="noopener noreferrer" style={{height:"inherit", display:'contents'}}>
      <img src={linkedInIcon} alt="n" style={{height:"50%"}}></img>
    </a>
    <a href = "mailto: BenT07891@gmail.com" style={{'fontSize': '80%', color:'white'}}>BenT07891@gmail.com</a>
  </React.Fragment>
)
import React from 'react';
import Olya_1 from './assets/Olya_1.jpg';
import olya_2 from './assets/olya_2.jpg';
import exit_sign from './assets/exit_sign.png';

export const Testimonials = ()=> {
  const [olyaPhotos, setolyaPhotos] = React.useState(false);

  if (olyaPhotos) {
    return <OlyaModal setolyaPhotos={setolyaPhotos}></OlyaModal>
  } else {
    return (
      <div id="testim">
        <figure id="fig1">
          <blockquote cite="https://www.linkedin.com/in/bentanaka/details/recommendations/">
              <p>During my time at Baxter Healthcare, Ben reported to me directly for a time and then I also partnered with Ben after he moved to another team. Ben was a fantastic team member and individual contributor while I worked with him. When ever the team needed his assistance he stepped in to work on anything other members of the team needed assistance with, from writing Medical Device Reports (MDRs) for submission to the FDA to investigating/writing/analyzing Corrective and Preventive Action (CAPA) records. Ben was always on top of completing his work and often assisted others when the need arose.
              <br></br>
              <br></br>
              I also partnered with Ben after he was selected to build a whole need team within the department, for the develop, management, and analysis of data from the new Post-Market Surveillance system that was implemented. Ben was a key piece of this team, time and time again single-handedly developing reports to cover critical workflows in the system. His knowledge with the Trackwise system that was in place and the back-end software to transform the data output into actionable reports was unmatched. Ben not only address and met the projects that were asked of him, but he also continually went above and beyond by also thinking a new ways to better the processes. It was a pleasure have the opportunity to not only manage Ben, but to also partner with him on projects during my time at Baxter Healthcare.</p>
          </blockquote>
          <figcaption className="tesimonialPerson">—Barrett M. <cite><a className="olyaSpan" href="https://www.linkedin.com/in/bentanaka/details/recommendations/" target="_blank" rel="noopener noreferrer">Source</a></cite></figcaption>
      </figure>
      <br></br>
      <figure id="fig2">
          <blockquote cite="https://www.linkedin.com/in/bentanaka/details/recommendations/">
              <p>I have always really enjoyed our interactions and am glad when we get to work together. He is by far, one of the most knowledgable and capable people that I have met. I believe that the many successful projects we had, were due to his involvement. He was not only able to provide solutions quickly, but also is technically able to carry such actions out. He is always prepared for anything we've done, whether it's a meeting or an action or a project. The fact that he also understands the complaint handling/MDR process/TW and many other aspects of the job, makes his particular skill set priceless.
              <br></br>
              <br></br>
              &#91;Regarding performance&#93; I don't have any such feedback because his performance has been fantastic, always.
              <br></br>
              <br></br>
              I think he has great emotional intelligence. He has always approached everything with good judgement and empathy for the groups involved. I've seen him adapt to the different changes and needs of the different groups and projects. He has done so with a positive attitude while always remaining understanding, calm and organized. I see him as wonderfully mature and able to speak about any situation we have had come up.
              <br></br>
              <br></br>
              I believe Ben has been especially great at &#91;collaberation&#93; because of the technical skills he possesses. He has been able to provide solutions across all teams and worked great with different levels of folks from those teams. When I tell someone that Ben Tanaka is on my team, it was always followed up by the person telling me something wonderful that Ben did for them or their team. He has quickly become the type of person everyone wants to have on their project/team because of the perspective he brings. Through my time working with him, I have seen him clearly communicate his ideas/solutions and identify problems others might not have noticed, while immediately following up with a way to resolve those problems.
              <br></br>
              <br></br>
              &#91;Regarding change&#93; I believe Ben really embodies &#91;it&#93;. I have seen Ben come into a new and very uncertain process, absort, and learn about it, to then be able to implement a standardized way that would run and be usable for any group that was involved. He has adapted to change and made changes quickly to do just about anything needed. He does so with a very positive outlook, and I have yet to see a roadblock that is anything but a minor speedbump for Ben. It's been good to have someone that has such capacity to see the big picture and understand the details that it would take to get whatever the action or project done.
              <br></br>
              <br></br>
              I don't think there ae any culture levers that he isn't already extremely strong in. He really has repeatedly demonstrated that he posesses very strong kill and ability, with every culture lever. I think he should keep having more opportunities to learn more processes, product lines, other groups' functions, etc. Because he truly could be improving every/any process he gets to see and learn about.
              </p>
          </blockquote>
          <figcaption className="tesimonialPerson">—Olya B. <span className="olyaSpan" onClick={()=>(setolyaPhotos(a=>!a))}>Source</span></figcaption>
      </figure>
    </div>
    )
  }
}

const OlyaModal = ({setolyaPhotos}) => (

  <div id="modal">
    <img className="exitSign" src={exit_sign} alt="EXIT" onClick={()=>(setolyaPhotos(a=>!a))}></img>
    <img className="olyaIMG" src={olya_2} alt="photo1 of feedback"></img>
    <img className="olyaIMG" src={Olya_1} alt="photo2 of feedback"></img>
  </div>

)